import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/seo'

import LandingPageLayout from '../components/layouts/landing-page-layout'
import Hero from '../components/landingPage/hero'
import Instructions from '../components/landingPage/instructions'
import VizContainer from '../components/landingPage/viz-container'
import ContentWrapper from '../components/common/content-wrapper'
import SimpleButton from '../components/controls/simple-button'
import { ButtonTypes } from '../utils/enum'
import { ColorGraphViz } from '../utils/theme'

//Styles
import '../styles/styles.scss'

const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query LandingPageQuery {
            allLandingPageCsv {
                nodes {
                    Section_content_1
                    Section_content_1_read_more
                    Section_content_2
                    Section_content_2_read_more
                    Section_content_3
                    Section_content_3_read_more
                    Section_title_1
                    Section_title_2
                    Section_title_3
                    Subtitle
                    Title
                    Hero_section
                    Inclusive_economy_description
                }
            }
        }
    `)

    const [showReadMoreFirstContent, setShowReadMoreFirstContent] = useState(false)
    const [showReadMoreSecondContent, setShowReadMoreSecondContent] = useState(false)
    const [showReadMoreThirdContent, setShowReadMoreThirdContent] = useState(false)

    const metadata = data.allLandingPageCsv.nodes[0]
    const readMoreButtonStyle = { color: ColorGraphViz.DarkGray, textTransform: 'none', fontSize: '16px', textAlign: 'left', fontWeight: 'bold' }
    const handleReadMore = (contentType) => {
        switch (contentType) {
            case 'first':
                return setShowReadMoreFirstContent(!showReadMoreFirstContent)
            case 'second':
                return setShowReadMoreSecondContent(!showReadMoreSecondContent)
            case 'third':
                return setShowReadMoreThirdContent(!showReadMoreThirdContent)
        }
    }
    return (
        <LandingPageLayout>
            <SEO />
            <Hero title={metadata.Title} description={metadata.Inclusive_economy_description} subtitle={metadata.Subtitle} content={metadata.Hero_section} />
            <Instructions />
            <VizContainer />
            <div className="additional-content">
                <ContentWrapper data={{ title: metadata['Section_title_1'], content: metadata['Section_content_1'] }}>
                    <SimpleButton
                        variant={ButtonTypes.Text}
                        label={showReadMoreFirstContent ? 'Read less' : 'Read more'}
                        iconName={showReadMoreFirstContent ? 'Remove' : 'Add'}
                        iconOrder={'end'}
                        customization={readMoreButtonStyle}
                        onClick={() => handleReadMore('first')}
                    />
                    {showReadMoreFirstContent && <div className="content" dangerouslySetInnerHTML={{ __html: metadata.Section_content_1_read_more }} />}
                </ContentWrapper>
                <ContentWrapper data={{ title: metadata['Section_title_2'], content: metadata['Section_content_2'] }}>
                    <SimpleButton
                        variant={ButtonTypes.Text}
                        label={showReadMoreSecondContent ? 'Read less' : 'Read more'}
                        iconName={showReadMoreSecondContent ? 'Remove' : 'Add'}
                        iconOrder={'end'}
                        customization={readMoreButtonStyle}
                        onClick={() => handleReadMore('second')}
                    />
                    {showReadMoreSecondContent && <div className="content" dangerouslySetInnerHTML={{ __html: metadata.Section_content_2_read_more }} />}
                </ContentWrapper>
                <ContentWrapper data={{ title: metadata['Section_title_3'], content: metadata['Section_content_3'] }}>
                    <SimpleButton
                        variant={ButtonTypes.Text}
                        label={showReadMoreThirdContent ? 'Read less' : 'Read more'}
                        iconName={showReadMoreThirdContent ? 'Remove' : 'Add'}
                        iconOrder={'end'}
                        customization={readMoreButtonStyle}
                        onClick={() => handleReadMore('third')}
                    />
                    {showReadMoreThirdContent && <div className="content" dangerouslySetInnerHTML={{ __html: metadata.Section_content_3_read_more }} />}
                </ContentWrapper>
            </div>
        </LandingPageLayout>
    )
}

export default IndexPage
