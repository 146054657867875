/**
 * Landing page layout
 */

import React, { useState } from 'react'

import Header from '../landingPage/header'
import TopNavigationBar from '../indicators/header'
import VerticalNavBar from '../indicators/vertical-nav-bar'

import Footer from '../common/footer'

import { TOP_NAV_BAR_OPTIONS } from '../../utils/const'

import './layout.scss'

const LandingPageLayout = ({ children }) => {
    const [showVerticalNavBar, setShowVerticalNavBar] = useState(false)

    const handleToggleVerticalNavBar = () => {
        setShowVerticalNavBar(!showVerticalNavBar)
    }

    return (
        <div className="layout-wrapper">
            <div className="content-wrapper" style={{ right: showVerticalNavBar ? '260px' : '0', position: showVerticalNavBar ? 'fixed' : 'relative' }}>
                <TopNavigationBar navOptions={TOP_NAV_BAR_OPTIONS} toggleVerticalNavBar={handleToggleVerticalNavBar} showVerticalNavBar={showVerticalNavBar} />
                <Header />
                <div className="content">
                    <main>{children}</main>
                </div>
                <Footer />
            </div>
            {showVerticalNavBar && (
                <div className="vertical-nav-bar">
                    <VerticalNavBar navOptions={TOP_NAV_BAR_OPTIONS} />
                </div>
            )}
        </div>
    )
}

export default LandingPageLayout
