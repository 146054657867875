import React, { useState, useRef, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import useMediaQuery from '@mui/material/useMediaQuery'

import SimpleButton from '../controls/simple-button'

import Dropdown from './dropdown'

import { ButtonTypes, Breakpoint } from '../../utils/enum'
import { ColorTopics } from '../../utils/theme'

import './hero.scss'

const Hero = ({ title, subtitle, description, content }) => {
    const inclusiveEconomybuttonRef = useRef(null)
    const exploreButtonRef = useRef(null)
    const [indicatorDropdownOpen, setIndicatorDropdown] = useState(false)
    const [miniDropdownOpen, setMiniDropdown] = useState(false)
    const [inclusivebuttonHeight, setInclusiveButtonHeight] = useState(0)
    const [exploreButtonHeight, setExploreButtonHeight] = useState(0)
    const isDesktop = useMediaQuery(`(min-width:${Breakpoint.Desktop}px)`)
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`)

    const buttonsFontSize = isDesktop ? '1.5rem' : '1.125rem'

    const inclusiveEconomyButtonStyle = {
        color: '#ffffff',
        border: `1px solid #ffffff`,
        textTransform: 'none',
        fontSize: buttonsFontSize,
        fontWeight: 'bold',
        textShadow: `0 0 6px rgba(0, 0, 0, 0.53)`,
        padding: '10px',
        width: '100%',
    }
    const executiveSummaryButtonStyle = {
        color: '#ffffff',
        border: `1px solid #ffffff`,
        textTransform: 'capitalize',
        fontSize: buttonsFontSize,
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'left',
    }
    const exploreIndicatorButtonStyle = {
        color: ColorTopics.Purple,
        backgroundColor: '#ffffff',
        border: `1px solid #ffffff`,
        textTransform: 'none',
        fontSize: buttonsFontSize,
        fontWeight: 'normal',
        width: '100%',
        textAlign: 'left',
        boxShadow: `0 3px 6px 0 rgba(0, 0, 0, 0.16)`,
    }

    useEffect(() => {
        if (inclusiveEconomybuttonRef.current) {
            setInclusiveButtonHeight(inclusiveEconomybuttonRef.current.clientHeight)
        }
    }, [inclusiveEconomybuttonRef])

    useEffect(() => {
        if (exploreButtonRef.current) {
            setExploreButtonHeight(exploreButtonRef.current.clientHeight)
        }
    }, [exploreButtonRef])

    const handleClick = () => {
        navigate(`/executiveSummary`)
    }

    return (
        <div className="hero-container">
            <div className="header-section">
                {/* <div className="header-section-item">
                    <ExternalLink url={CEI_WEBSITE} sameTab={false}>
                        <StaticImage src="../../images/cei-logo-purple.png" alt="CEI logo" />
                    </ExternalLink>
                </div> */}
                <div className="header-section-item title-section">
                    <div className="sub-title">{subtitle}</div>
                    <div className="title">{title}</div>
                </div>
            </div>
            <div className="content-section">
                <div className="content text" dangerouslySetInnerHTML={{ __html: content }} />
                <div className="content buttons">
                    <div className="button-container">
                        <div ref={inclusiveEconomybuttonRef} onMouseEnter={() => setMiniDropdown(true)} onMouseLeave={() => setMiniDropdown(false)}>
                            <SimpleButton variant={ButtonTypes.Outlined} label={'What is an Inclusive Economy?'} customization={inclusiveEconomyButtonStyle} />
                            {miniDropdownOpen && (
                                <div className="mini-dropdown" style={{ top: `${inclusivebuttonHeight - 5}px` }}>
                                    <hr style={{ top: `-${inclusivebuttonHeight * 0.25}px` }} />
                                    <div>{description}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="button-container">
                        <SimpleButton
                            variant={ButtonTypes.Outlined}
                            label={'Executive Summary'}
                            customization={executiveSummaryButtonStyle}
                            iconName="ArrowForward"
                            iconOrder="end"
                            onClick={handleClick}
                        />
                    </div>
                    <div className="button-container">
                        <div ref={exploreButtonRef} onMouseEnter={() => setIndicatorDropdown(true)} onMouseLeave={() => setIndicatorDropdown(false)}>
                            <SimpleButton variant={ButtonTypes.Outlined} label={'Explore an Indicator'} customization={exploreIndicatorButtonStyle} iconName="KeyboardArrow" iconOrder="end" />
                            {indicatorDropdownOpen && <Dropdown />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
