import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Topics, Breakpoint } from '../../utils/enum'
import { COLOR_MAP_TOPICS } from '../../utils/theme'

import BusinessDevelopmentImg from '../../images/BusinessDevelopment_icon.png'
import HumanCapitalImg from '../../images/HumanCapital_icon.png'
import InclusiveGrowthImg from '../../images/InclusiveGrowth_icon.png'
import SenseBelonging from '../../images/SenseBelonging_icon.png'
import AccessOpportunity from '../../images/AccessOpportunity_icon.png'

import './header.scss'

const Header = () => {
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`)

    const listIcons = Object.keys(Topics)
        .filter((key) => key !== Topics.Demographics && key !== Topics.Neighborhoods)
        .map((key) => {
            const path = topicIconPath(Topics[key])
            const backgroundColor = COLOR_MAP_TOPICS[Topics[key]]
            return (
                <div className="topic-container" key={`icon-${key}`} style={{ backgroundColor: backgroundColor }}>
                    <div key={key} className="ig-icon">
                        <img src={path} alt={Topics[key]} />
                    </div>
                </div>
            )
        })
    return (
        <header>
            <div className="landing-page-container">{listIcons}</div>
        </header>
    )
}

export default Header

const topicIconPath = (topic) => {
    switch (topic) {
        case Topics.BusinessDevelopment:
            return BusinessDevelopmentImg
        case Topics.HumanCapital:
            return HumanCapitalImg
        case Topics.InclusiveGrowth:
            return InclusiveGrowthImg
        case Topics.SenseBelonging:
            return SenseBelonging
        case Topics.AccessOpportunity:
            return AccessOpportunity
        default:
            console.log('Icon not found.')
    }
}
