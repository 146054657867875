import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import './instructions.scss'

const Instructions = () => {
    return (
        <div className="instructions-container">
            <div className="instructions-item">
                <h2 className="title">How to use the Indicator Visualization</h2>
                <div className="content">
                    Use your mouse to hover over and explore the interactive below. Hovering over a specific indicator displays which cultural communities have data available. You can also hover over
                    a cultural community to see which Indicators have data for that community.
                </div>
            </div>
            <div className="instructions-item instructions-img">
                <div>
                    <StaticImage src="../../images/Instructions.png" alt="How to interact with the Indicator Visualization" width={700} />
                </div>
            </div>
        </div>
    )
}

export default Instructions
