import * as React from 'react'
import { Link } from 'gatsby'

import { useIndicators } from '../../hooks/use-indicators'

import './dropdown.scss'

const Dropdown = () => {
    const indicators = useIndicators()
    const filteredIndicators = indicators.filter((d) => d.Topic_area !== 'Neighborhoods' && d.Indicator_code.match(/^[a-z0-9]+$/i))
    const indicatorsList = filteredIndicators.map((indicator) => {
        return (
            <div className="dropdown-item" key={indicator.Indicator_code}>
                <Link to={`/indicators/${indicator.Indicator_code}`}>{indicator.Indicator_short_name}</Link>
            </div>
        )
    })
    return (
        <div className="dropdown-container">
            <ul>{indicatorsList}</ul>
        </div>
    )
}

export default Dropdown
