import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import _ from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import { group } from 'd3-array'
import { linkHorizontal } from 'd3-shape'
import { scaleBand } from 'd3-scale'

import { useTopics } from '../../hooks/use-topics'
import Line from '../common/svg/line'
import { COLOR_MAP_TOPICS, ColorGraphViz } from '../../utils/theme'
import { Topics, Breakpoint } from '../../utils/enum'
import { truncateString } from '../../utils/format'

import InclusiveGrowthSvg from '../../images/InclusiveGrowth.svg'
import BusinessDevelopmentSvg from '../../images/BusinessDevelopment.svg'
import HumanCapitalSvg from '../../images/HumanCapital.svg'
import SenseBelongingSvg from '../../images/SenseBelonging.svg'
import AccessOpportunitySvg from '../../images/AccessOpportunity.svg'
import ClickIcon from '../../images/click-icon.png'

import './graph-viz.scss'

const linkGenerator = linkHorizontal()
    .source((d) => d.source)
    .target((d) => d.target)
    .x((d) => d[0])
    .y((d) => d[1])

const DEFAULT_MARGIN = {
    top: 70,
    right: 100,
    bottom: 0,
    left: 100,
}

const NODE_HEIGHT = 25
const DEFAULT_INDICATOR_NODE_WIDTH = 420
const DEFAULT_COMMUNITY_NODE_WIDTH = 400
const PADDING_INNER = 5
const PADDING_OUTER = 8
const IMAGE_SIZE = 60
const MARGIN_TEXT = 20

const GraphViz = ({ data }) => {
    const topics = useTopics()
    const containerRef = useRef(null)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [links, setLinks] = useState([])
    const [indicatorNodeWidth, setIndicatorNodeWidth] = useState(DEFAULT_INDICATOR_NODE_WIDTH)
    const [communityNodeWidth, setCommunityNodeWidth] = useState(DEFAULT_COMMUNITY_NODE_WIDTH)
    const [margin, setMargin] = useState(DEFAULT_MARGIN)

    const [hoveredNode, setHoveredNode] = useState('')
    const [hoveredTopic, setHoveredTopic] = useState('')

    const isLargeDesktop = useMediaQuery(`(min-width:${Breakpoint.Desktop}px)`, { noSsr: true })
    const isDesktop = useMediaQuery(`(min-width:${Breakpoint.TabletWide}px`, { noSsr: true })
    const isTabletWide = useMediaQuery(`(max-width:${Breakpoint.TabletWide}px)`, { noSsr: true })
    const isMobile = useMediaQuery(`(max-width:${Breakpoint.Mobile}px)`, { noSsr: true })

    const topicGroups = Array.from(group(data, (d) => d.Topic_Area))
    let raceGroups = Array.from(group(data, (d) => d.Race))
    raceGroups = raceGroups.sort((a, b) => (a[0] > b[0] ? 1 : -1)) //Sort it alphabetically
    const indicatorGroups = Array.from(group(data, (d) => d.Indicator_id))

    const fullHeight = indicatorGroups.length * (NODE_HEIGHT + PADDING_INNER) + topicGroups.length * PADDING_OUTER + margin.top

    let indicatorColumn = []
    let communityColumn = []
    let topicIcons = []
    const indicatorNodesMap = {} //To store node positions
    const communityNodesMap = {}

    useEffect(() => {
        if (containerRef.current && data.length > 0) {
            setWidth(containerRef.current.clientWidth)
            setHeight(fullHeight)
        }
    }, [data, containerRef])

    //Responsive node width
    useEffect(() => {
        //Tablet
        if (isTabletWide && !isMobile) {
            setIndicatorNodeWidth(DEFAULT_INDICATOR_NODE_WIDTH * 0.7)
            setCommunityNodeWidth(DEFAULT_COMMUNITY_NODE_WIDTH * 0.7)
            setMargin({
                top: 80,
                right: 30,
                bottom: 0,
                left: 0,
            })
        } else if (isMobile && isTabletWide) {
            setIndicatorNodeWidth(DEFAULT_INDICATOR_NODE_WIDTH * 0.35)
            setCommunityNodeWidth(DEFAULT_COMMUNITY_NODE_WIDTH * 0.25)
            setMargin({
                top: 80,
                right: 15,
                bottom: 0,
                left: 0,
            })
        } else if (isDesktop && !isLargeDesktop) {
            setIndicatorNodeWidth(DEFAULT_INDICATOR_NODE_WIDTH * 0.8)
            setCommunityNodeWidth(DEFAULT_COMMUNITY_NODE_WIDTH * 0.75)
            setMargin({
                top: 80,
                right: 45,
                bottom: 0,
                left: 70,
            })
        } else {
            setIndicatorNodeWidth(DEFAULT_INDICATOR_NODE_WIDTH)
            setCommunityNodeWidth(DEFAULT_COMMUNITY_NODE_WIDTH)
            setMargin(DEFAULT_MARGIN)
        }
    }, [isDesktop, isTabletWide, isMobile])

    const generateIndicatorNodes = () => {
        if (data.length > 0) {
            //Create indicator nodes
            let accHeight = !isTabletWide ? -margin.top * 0.25 : 0 // To be able to have variable band heights
            indicatorColumn = topicGroups.map((topic) => {
                const groupedIndicators = Array.from(group(topic[1], (d) => d.Indicator_id))
                const height = groupedIndicators.length * (NODE_HEIGHT + PADDING_INNER)
                const yPos = accHeight
                accHeight = accHeight + height + PADDING_OUTER

                const localYScale = scaleBand()
                    .domain(groupedIndicators.map((d) => d[0]))
                    .range([0, height])

                return (
                    <g key={topic} transform={`translate(0,${yPos})`}>
                        {groupedIndicators.map((group) => {
                            indicatorNodesMap[group[0]] = [margin.left + indicatorNodeWidth, margin.top + yPos + localYScale(group[0]) + NODE_HEIGHT * 0.5]
                            let indicatorName = group[1][1].Indicator__full_ //All items include the same indicator name
                            indicatorName = isMobile ? truncateString(indicatorName, 12) : indicatorName
                            const indicatorCode = group[1][1].Indicator_id
                            const mappedLink = links?.filter((d) => d.targetId === indicatorCode)
                            let fillColor = ColorGraphViz.White
                            let fillColorArrow = COLOR_MAP_TOPICS[topic[0]]
                            let fontColor = ''
                            if (links.length > 0 && hoveredNode === indicatorCode) {
                                fillColor = COLOR_MAP_TOPICS[topic[0]]
                                fontColor = ColorGraphViz.White
                                fillColorArrow = ColorGraphViz.White
                            }
                            if (links.length > 0 && Object.keys(links[0]).includes('race') && mappedLink.length > 0) {
                                fillColor = ColorGraphViz.DarkGray
                                fontColor = ColorGraphViz.White
                                fillColorArrow = ColorGraphViz.DarkGray
                            }

                            return (
                                <g
                                    key={`indicator-col-${group[0]}`}
                                    onMouseEnter={() => handleIndicatorHovering(group[0])}
                                    onMouseLeave={() => setLinks([])}
                                    onClick={() => handleIndicatorSelection(group[0])}
                                >
                                    <rect
                                        className="indicator-node"
                                        x={0}
                                        y={localYScale(group[0])}
                                        width={indicatorNodeWidth}
                                        height={NODE_HEIGHT}
                                        fill={fillColor}
                                        stroke={ColorGraphViz.Gray}
                                        strokeWidth="0.5"
                                        rx="5"
                                    />
                                    <rect x={0} y={localYScale(group[0])} width={10} height={NODE_HEIGHT} fill={COLOR_MAP_TOPICS[topic[0]]} rx="5" />
                                    <text x={MARGIN_TEXT} y={localYScale(group[0]) + NODE_HEIGHT * 0.75} fill={fontColor}>
                                        {indicatorName}
                                    </text>
                                    <polygon
                                        transform={`translate(${indicatorNodeWidth - 20},${localYScale(group[0]) + NODE_HEIGHT * 0.4})`}
                                        points={`${NODE_HEIGHT * 0.25},${NODE_HEIGHT * 0.12} 0,${NODE_HEIGHT * 0.25} 0,0`}
                                        fill={fillColorArrow}
                                    />
                                </g>
                            )
                        })}
                    </g>
                )
            })
        }
    }

    const generateCommunityNodes = () => {
        if (data.length > 0) {
            let accHeight = !isTabletWide ? -margin.top * 0.25 : 0
            const paddingOuterCommunities = (raceGroups.length * (NODE_HEIGHT + PADDING_INNER)) / topicGroups.length //Padding between race groups needs to be larger

            communityColumn = raceGroups.map((race) => {
                const groupedCommunities = Array.from(group(race[1], (d) => d.Community_Group))
                const height = groupedCommunities.length * (NODE_HEIGHT + PADDING_INNER)
                const yPos = accHeight
                accHeight = accHeight + height + paddingOuterCommunities
                const localYScale = scaleBand()
                    .domain(groupedCommunities.map((d) => d[0]))
                    .range([0, height])

                return (
                    <g key={race} transform={`translate(0,${yPos})`}>
                        {groupedCommunities.map((group) => {
                            communityNodesMap[group[0]] = [width - communityNodeWidth - margin.right, margin.top + yPos + localYScale(group[0]) + NODE_HEIGHT * 0.5]
                            let communityName = group[1][1].Community_Group
                            communityName = isMobile ? truncateString(communityName, 10) : communityName
                            const mappedLink = links?.filter((d) => d.targetId === communityName)

                            let fillColor = ColorGraphViz.White
                            let fontColor = ''
                            let circleFillColor = ColorGraphViz.Silver
                            if (links.length > 0 && Object.keys(links[0]).includes('topic') && mappedLink.length > 0) {
                                fillColor = COLOR_MAP_TOPICS[mappedLink[0].topic]
                                fontColor = ColorGraphViz.White
                                circleFillColor = COLOR_MAP_TOPICS[mappedLink[0].topic]
                            }
                            if (links.length > 0 && hoveredNode === communityName) {
                                fillColor = ColorGraphViz.DarkGray
                                fontColor = ColorGraphViz.White
                                circleFillColor = ColorGraphViz.DarkGray
                            }

                            return (
                                <g key={`commmunity-col-${group[0]}`} onMouseEnter={() => handleCommunityHovering(group[0])} onMouseLeave={() => setLinks([])}>
                                    <rect
                                        key={`community-${group[0]}`}
                                        x={0}
                                        y={localYScale(group[0])}
                                        width={communityNodeWidth}
                                        height={NODE_HEIGHT}
                                        fill={fillColor}
                                        stroke={ColorGraphViz.Gray}
                                        strokeWidth="0.5"
                                        rx="5"
                                    />
                                    <text x={MARGIN_TEXT} y={localYScale(group[0]) + NODE_HEIGHT * 0.75} fill={fontColor} className={communityName === race[0] ? 'parent-category' : ''}>
                                        {communityName}
                                    </text>
                                    <circle cx={0} cy={localYScale(group[0]) + NODE_HEIGHT * 0.5} r={5} fill={circleFillColor} />
                                </g>
                            )
                        })}
                    </g>
                )
            })
        }
    }

    const generateTopicIcons = () => {
        if (data.length > 0) {
            let accHeight = !isTabletWide ? -margin.top * 0.25 : 0
            topicIcons = topicGroups.map((topic) => {
                const groupedIndicators = Array.from(group(topic[1], (d) => d.Indicator_id))
                const height = groupedIndicators.length * (NODE_HEIGHT + PADDING_INNER)
                const yPos = accHeight
                accHeight = accHeight + height + PADDING_OUTER
                const path = topicIconPath(topic[0])
                const topicDescription = topics.find((d) => d.Topic_area === topic[0]).Topic_area_description

                return (
                    <g key={topic} transform={`translate(0,${yPos + margin.top})`} onMouseEnter={() => handleTopicHovering(topic[0])} onMouseLeave={() => setHoveredTopic('')}>
                        {hoveredTopic && hoveredTopic === topic[0] && (
                            <foreignObject x={0} y={height * 0.5 - IMAGE_SIZE} width="60%" height={IMAGE_SIZE * 2}>
                                <div
                                    xmlns="http://www.w3.org/1999/xhtml"
                                    className="topic-description"
                                    style={{ paddingLeft: IMAGE_SIZE * 0.65, paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px', backgroundColor: COLOR_MAP_TOPICS[topic[0]] }}
                                >
                                    <div className="topic-title">{topic[0]}</div>
                                    <div dangerouslySetInnerHTML={{ __html: topicDescription }} />
                                </div>
                            </foreignObject>
                        )}

                        <image href={path} x={-IMAGE_SIZE * 0.5} y={height * 0.5 - IMAGE_SIZE * 0.5} height={IMAGE_SIZE} width={IMAGE_SIZE} />
                    </g>
                )
            })
        }
    }

    generateIndicatorNodes()
    generateCommunityNodes()
    generateTopicIcons()

    const handleIndicatorHovering = (id) => {
        setHoveredNode(id)
        const filteredData = data.filter((d) => d.Indicator_id === id && d.Data_Available === 'TRUE')
        const formattedLinks = filteredData.map((connection) => {
            return {
                sourceId: id,
                source: indicatorNodesMap[id],
                targetId: connection.Community_Group,
                target: communityNodesMap[connection.Community_Group],
                topic: connection.Topic_Area,
            }
        })
        setLinks(formattedLinks)
    }

    const handleCommunityHovering = (id) => {
        setHoveredNode(id)
        const filteredData = data.filter((d) => d.Community_Group === id && d.Data_Available === 'TRUE')
        const formattedLinks = filteredData.map((connection) => {
            return {
                sourceId: id,
                source: communityNodesMap[id],
                target: indicatorNodesMap[connection.Indicator_id],
                targetId: connection.Indicator_id,
                race: connection.Race,
            }
        })
        setLinks(formattedLinks)
    }
    const handleIndicatorSelection = (id) => {
        navigate(`/indicators/${id}`)
    }

    const handleTopicHovering = (id) => {
        setHoveredTopic(id)
    }

    const lastTopicGroup = Array.from(group(topicGroups[topicGroups.length - 1][1], (d) => d.Indicator_id))
    const lastTopicGroupHeight = (lastTopicGroup.length - 1) * NODE_HEIGHT

    const showTopicsColumn = isLargeDesktop || isDesktop

    return (
        <div className="graph-viz-container" ref={containerRef}>
            <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
                <g className="nodes" transform={`translate(${margin.left},${margin.top})`}>
                    {isDesktop && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Indicator for an Inclusive Regional Economy
                        </text>
                    )}
                    {isTabletWide && !isMobile && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Indicator for an Inclusive
                            <tspan x={0} dy="2.8%">
                                Regional Economy
                            </tspan>
                        </text>
                    )}
                    {isMobile && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Indicator for an
                            <tspan x={0} dy="2.8%">
                                Inclusive Economy
                            </tspan>
                        </text>
                    )}
                    {indicatorColumn}
                </g>

                <g className="nodes" transform={`translate(${width - communityNodeWidth - margin.right},${margin.top})`}>
                    {isDesktop && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Cultural Community Represented
                        </text>
                    )}
                    {isTabletWide && !isMobile && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Cultural Community
                            <tspan x={0} dy="2.8%">
                                Represented
                            </tspan>
                        </text>
                    )}
                    {isMobile && (
                        <text y={-margin.top * 0.5} className="column-title">
                            Cultural
                            <tspan x={0} dy="2.8%">
                                Community
                            </tspan>
                        </text>
                    )}
                    {communityColumn}
                </g>
                {links && (
                    <g fill="none">
                        {links.map((link, idx) => {
                            const color = Object.keys(link).includes('topic') ? COLOR_MAP_TOPICS[link.topic] : ColorGraphViz.DarkGray
                            return (
                                <g key={`link-${idx}`}>
                                    <path d={linkGenerator(link)} stroke={color} strokeWidth="3"></path>
                                </g>
                            )
                        })}
                    </g>
                )}
                {showTopicsColumn && (
                    <g className="topic-column" transform={`translate(${margin.left * 0.5},0)`}>
                        <Line
                            data={[
                                [0, 25],
                                [0, fullHeight - lastTopicGroupHeight],
                            ]}
                            styles={{ strokeWidth: 15, stroke: ColorGraphViz.LightGray }}
                        />
                        <image href={ClickIcon} x={-IMAGE_SIZE * 0.25} y={0} height={IMAGE_SIZE * 0.5} width={IMAGE_SIZE * 0.5} />
                        {topicIcons}
                    </g>
                )}
            </svg>
        </div>
    )
}

export default GraphViz

const topicIconPath = (topic) => {
    switch (topic) {
        case Topics.BusinessDevelopment:
            return BusinessDevelopmentSvg
        case Topics.HumanCapital:
            return HumanCapitalSvg
        case Topics.InclusiveGrowth:
            return InclusiveGrowthSvg
        case Topics.SenseBelonging:
            return SenseBelongingSvg
        case Topics.AccessOpportunity:
            return AccessOpportunitySvg
        default:
            console.log('Icon not found.')
    }
}
