import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import GraphViz from './graph-viz'

import './viz-container.scss'

const VizContainer = () => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            allDataConnectionsCsv {
                nodes {
                    Race
                    Community_Group
                    Data_Available
                    Indicator__full_
                    Indicator_id
                    Topic_Area
                }
            }
        }
    `)

    const visualizationData = data.allDataConnectionsCsv.nodes
    return (
        <div className="viz-container">
            <GraphViz data={visualizationData} />
        </div>
    )
}

export default VizContainer
